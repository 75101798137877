import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Grid, Card, Modal, TableRow, TableCell, TableHead, TableContainer, Paper, TableBody, Table, CardContent, Box, Container, Typography, useMediaQuery, CircularProgress, TextField, ThemeProvider, CssBaseline, Button } from '@mui/material';
import MainNav from '../../components/navigation/MainNav';
import { api } from '../../services/apis';
import { mainTheme, formatCurrency, matchHeaders } from '../../services/settings';
import { useTheme } from '@mui/material/styles';


const NRMAFloatTable = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const userRole = localStorage.getItem('role');
  const [floatALoading, setFloatALoading] = useState(true);
  const [floatATopUpLoading, setFloatATopUpLoading] = useState(true);
  const [nRMATopUpValue, setNRMATopUpValue] = useState(0);
  const [NRMAFloatSummary, setNRMAFloatSummary] = useState({
    totalPayouts: 0,
    numberOfPayouts: 0,
    averageAmountPerDay: 0,
    availableBalance: 0,
    daysLeft: 0,
    pendingPayouts: 0,
    numberOfPendingPayouts: 0,
    failedPayouts: 0,
    numberOfFailedPayouts: 0,
  });
  
  const navigate = useNavigate(); 

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([fetchNRMAFloat(), fetchNRMATopUp()]);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const fetchNRMAFloat = async () => {
    try {
      const headers = await matchHeaders(userRole);
      const response = await axios.get(api.retrieveNrmaFloat, {
        headers: {
          'Content-Type': 'application/json',
          apikey: headers.apikey,
          tenant: headers.appTenant,
          tenantA: headers.authTenant,
        },
      });
      setNRMAFloatSummary(response.data.summary);
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setFloatALoading(false);
    }
  };

  const fetchNRMATopUp = async () => {
    try {
      const headers = await matchHeaders(userRole);
      const response = await axios.get(api.retrieveNrmaTopUp, {
        headers: {
          'Content-Type': 'application/json',
          apikey: headers.apikey,
          tenant: headers.appTenant,
          tenantA: headers.authTenant,
        },
      });
      setNRMATopUpValue(response.data.suggestedTopUpValue);
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setFloatATopUpLoading(false);
    }
  };

  return (
    <Container maxWidth="xxl"> {/* Adjusted to md for a more centered layout */}
      <Box
        sx={{
          padding: '20px',
          marginLeft: isMobile ? '0' : 'auto', // For larger screens, keep it centered
          marginRight: isMobile ? '0' : 'auto', 
          marginTop: '30px',
          transition: 'margin-left 0.3s', 
        }}
      >
        <Typography variant="h6" sx={{ marginBottom: '20px' }}>NRMA</Typography>
  
        <TableContainer component={Paper} sx={{ width: '500px' }}> {/* Use full width available */}
          <Table aria-label="NRMA Float Summary">
            <TableHead>
              <TableRow sx={{backgroundColor: '#3ebeed'}}>
                <TableCell sx={{fontSize: 14, fontWeight: 'bold', color: '#fff'}}>Details</TableCell>
                <TableCell align="right" sx={{fontSize: 14, fontWeight: 'bold', color: '#fff'}}>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Total Payouts</TableCell>
                <TableCell align="right">{formatCurrency(-NRMAFloatSummary.totalPayouts)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Number of Payouts</TableCell>
                <TableCell align="right">{NRMAFloatSummary.numberOfPayouts}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Average Amount Per Day</TableCell>
                <TableCell align="right">{formatCurrency(-NRMAFloatSummary.averageAmountPerDay)}</TableCell>
              </TableRow>
              <TableRow sx={{ backgroundColor: '#e8f9ff'}}>
                <TableCell >Available Balance</TableCell>
                <TableCell align="right">{formatCurrency(NRMAFloatSummary.availableBalance)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Pending Payouts</TableCell>
                <TableCell align="right">{NRMAFloatSummary.numberOfPendingPayouts}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Pending Amount</TableCell>
                <TableCell align="right">{formatCurrency(NRMAFloatSummary.pendingPayouts)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Failed Payouts</TableCell>
                <TableCell align="right">{NRMAFloatSummary.numberOfFailedPayouts}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Failed Amount Amount</TableCell>
                <TableCell align="right">{formatCurrency(NRMAFloatSummary.failedPayouts)}</TableCell>
              </TableRow>

              <TableRow sx={{ backgroundColor: '#f2e9e9'}}>
                <TableCell >Days Left</TableCell>
                <TableCell align="right">{(NRMAFloatSummary.daysLeft).toFixed(2)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Suggested Top Up Value</TableCell>
                <TableCell align="right">{formatCurrency(-nRMATopUpValue)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
  
        {nRMATopUpValue !== 0 && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px', marginTop: '40px' }}>
            <Button variant="contained" sx={{ backgroundColor: theme.palette.action.add }}>Top Up Now</Button>
          </Box>
        )}
      </Box>
    </Container>
  );
  
};

export default NRMAFloatTable;
