import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Users from './pages/Users';
import UserDetails from './pages/UserDetails';
import LoginPage from './auth/login'
import LogoutPage from './auth/logout';
import FloatsPage from './pages/Floats'
import Clubs from './pages/Clubs';
import InvoicesPage from './pages/Invoices';
import InvoiceDetailsPage from './pages/InvoiceDetailsPage';
import NewClubForm from './pages/Clubs/newClub';
import ClubDetails from './pages/Clubs/details';
import DashboardNew from './pages/DashboardNew';
import Transactions from './pages/Transactions';
import BillingPage from './pages/Billing';
import RewardsIQDashboard from './pages/RewardsIQ';
import ChurnDashboard from './pages/RewardsIQ/churn';
import ClubsBillingPage from './pages/Clubs/billing';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/dashboard" element={<DashboardNew />} />
        <Route path="/users" element={<Users />} />
        <Route path="/transactions" element={<Transactions />} />
        <Route path="/users/:userId" element={<UserDetails />} />
        <Route path="/" element={<LoginPage />} />
        <Route path="/logout" element={<LogoutPage />} />
        <Route path="/floats" element={<FloatsPage />} />
        <Route path="/clubs" element={<Clubs />} />
        <Route path="/clubs/:clubId" element={<ClubDetails />} />
        <Route path="/clubs/new" element={<NewClubForm />} />
        <Route path="/clubs/eombilling" element={<ClubsBillingPage />} />
        <Route path="/invoices" element={<InvoicesPage />} />
        <Route path="/invoices/:invoiceId" element={<InvoiceDetailsPage />} /> {/* New route */}
        <Route path="/billing" element={<BillingPage />} />

        <Route path="/rewardsiq" element={<RewardsIQDashboard />} />
        <Route path="/rewardsiq/churn" element={<ChurnDashboard />} />

     </Routes>
    </Router>
  );
}

export default App;
