import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { Container, TextField, Button, Checkbox, FormControlLabel, Typography, Box, ThemeProvider, CssBaseline, FormControl,useMediaQuery, InputLabel, Select, MenuItem} from '@mui/material';
import { mainTheme, matchHeaders } from '../../services/settings';
import MainNav from '../../components/navigation/MainNav';
import { api } from '../../services/apis';

const NewClubForm = () => {
    const theme = mainTheme;
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const [clubList, setClubList] = useState([]);
  const [loadingClubs, setLoadingClubs] = useState(true);
  const userRole = localStorage.getItem('role');
  const [clubDetails, setClubDetails] = useState({
    createdDate: '',
    displayName: '',
    parent_club: '',
    firstName: '',
    lastName: '',
    email: '',
    accountName: '',
    branch_code: '',
    account_number: '',
    qr_identity: '',
    verified: false,
    dgrStatus: false,
  });

  const navigate = useNavigate(); // Use navigate for navigation


  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setClubDetails({
      ...clubDetails,
      [name]: value,
    });
  };

  // Handle checkbox change for boolean values
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setClubDetails({
      ...clubDetails,
      [name]: checked,
    });
  };

  // Placeholder for form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
        !clubDetails.displayName || 
        !clubDetails.firstName || 
        !clubDetails.lastName || 
        !clubDetails.email ||
        !clubDetails.accountName || 
        !clubDetails.branch_code || 
        !clubDetails.account_number ||
        !clubDetails.verified ||
        !clubDetails.qr_identity
      ) {
        alert('Please fill out all required fields.');
        return;
      }

    try {
        const headers = await matchHeaders(userRole);
        const response = await axios.post(
            api.registerClub, 
            {  displayName: clubDetails.displayName, parent_club: clubDetails.parent_club, firstName: clubDetails.firstName, lastName: clubDetails.lastName, email: clubDetails.email, accountName: clubDetails.accountName, branch_code: clubDetails.branch_code, account_number: clubDetails.account_number, verified: clubDetails.verified, dgrStatus: clubDetails.dgrStatus, qr_identity: clubDetails.qr_identity},
            {
          headers: {
            'Content-Type': 'application/json',
            apikey: headers.apikey,
            tenant: headers.appTenant,
            tenantA: headers.authTenant,
          },
        });
        if (response.status === 200 || response.status === 201) { // Check if the response is successful
            alert('Club details submitted successfully!');
            navigate('/clubs')
          }
      
        } catch (error) {
            console.error('Error submitting club details:', error);
            if (error.response) {
              console.error('Error response data:', error.response.data); // Additional logging for response error
            }
            alert('Error submitting club details. Please try again.');
          }
        };

  const retrieveClubList = async () => {
    try {
            const headers = await matchHeaders(userRole);
            const response = await axios.get(api.listClubNames, {
              headers: {
                'Content-Type': 'application/json',
                apikey: headers.apikey,
                tenant: headers.appTenant,
                tenantA: headers.authTenant,
              }
            });

      const clubs = response.data.clubs;
      setClubList(clubs);
    } catch (error) {
      console.error('Error fetching club list:', error);
    } finally {
      setLoadingClubs(false);    
  }};

  useEffect(() => {
    retrieveClubList();
  }, []);

  return (
    <Container maxWidth="xxl">
      <ThemeProvider theme={mainTheme}>
        <CssBaseline />
        <MainNav />
        <Box
         sx={{
          padding: '20px',
          marginLeft: isMobile ? '0' : '300px', // 240px for sidebar width on larger screens
          marginTop: '30px',  
          transition: 'margin-left 0.3s', // Smooth transition when screen size changes
          alignItems: 'left',
          maxWidth: '900px'
        }}
      >
          <Typography variant="h4" gutterBottom>
            New Club Details
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Created Date"
              name="createdDate"
              type="date"
              value={clubDetails.createdDate}
              onChange={handleInputChange}
              fullWidth
              sx={{ mb: 2 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              label="Club Name"
              name="displayName"
              value={clubDetails.displayName}
              onChange={handleInputChange}
              fullWidth
              sx={{ mb: 2 }}
            />
            {/* Parent Club Dropdown */}
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Parent Club</InputLabel>
              <Select
                name="parent_club"
                value={clubList._id}
                onChange={handleInputChange}
                label="Parent Club"
                disabled={loadingClubs} // Disable dropdown while loading
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {clubList.map((club) => (
                  <MenuItem key={club._id} value={club._id}>
                    {club.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="First Name"
              name="firstName"
              value={clubDetails.firstName}
              onChange={handleInputChange}
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              label="Last Name"
              name="lastName"
              value={clubDetails.lastName}
              onChange={handleInputChange}
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              label="Email"
              name="email"
              value={clubDetails.email}
              onChange={handleInputChange}
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              label="Account Name"
              name="accountName"
              value={clubDetails.accountName}
              onChange={handleInputChange}
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              label="BSB"
              name="branch_code"
              value={clubDetails.branch_code}
              onChange={handleInputChange}
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              label="Account Number"
              name="account_number"
              value={clubDetails.account_number}
              onChange={handleInputChange}
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              label="QR Identity"
              name="qr_identity"
              value={clubDetails.qr_identity}
              onChange={handleInputChange}
              fullWidth
              sx={{ mb: 2 }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="verified"
                  checked={clubDetails.verified}
                  onChange={handleCheckboxChange}
                />
              }
              label="Verified"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="dgrStatus"
                  checked={clubDetails.dgrStatus}
                  onChange={handleCheckboxChange}
                />
              }
              label="DGR Status"
            />
            <Box sx={{ mt: 2 }}>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </ThemeProvider>
    </Container>
  );
};

export default NewClubForm;
